@import "../../index.scss";

.stfdash {
  padding: 20px;
  min-height: 60vh;
  background: #f5f5f5; // Light background for the dashboard

  &-contents {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;

    &-info,
    &-classes,
    &-ancmnts,
    &-exam {
      background: #ffffff; // White background for content blocks
      border-radius: 10px; // Rounded corners for the content blocks
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      padding: 20px;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px); // Slight lift effect on hover
      }
    }

    &-info {
      width: 100%; // Full width on smaller screens
      max-width: 350px; // Limit width on larger screens
      text-align: center; // Center the text for a cleaner look

      &-brand {
        display: flex;
        // flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        background-color: $primary;
        padding: 1rem 0;
        border-radius: 1rem;

        div {
          color: white;
          font-weight: 600;
          font-size: 1.8rem;
        }

        img {
          width: 5rem; // Adjust logo size
          height: auto;
          border-radius: 50%;
        }
      }

      &-name {
        margin-top: 20px;
        h3 {
          font-weight: bold;
          color: $secondary; // Use secondary color from your theme
        }
      }

      .student-photo {
        margin-top: 20px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
      }

      &-details {
        text-align: left;
        margin-top: 1rem;
      }
    }

    &-classes,
    &-ancmnts,
    &-exam {
      flex: 1; // Take available space
      min-width: 250px; // Minimum width for readability
      max-height: 60vh;
      overflow: scroll;

      &-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 15px;
        color: $primary; // Use a primary color for titles
      }
    }

    &-ancmnts {
      flex: 2;
    }
  }
}
