@import "../../index.scss";

.study {
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  min-height: 70vh;

  .study-header {
    background-color: #007bff;
    color: white;
    padding: 20px;
    text-align: center;

    .study-header-title {
      font-size: 24px;
    }
  }

  .study-contents {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;

    .study-contents-class {
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      margin-bottom: 10px;

      .study-contents-class-meta {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0.5rem;

        .study-contents-class-meta-name {
          font-size: 2.4rem;
          font-weight: 700;
          // cursor: pointer;
          &:hover {
            color: #0056b3;
          }
        }

        .study-contents-class-meta-add {
          button {
            padding: 10px 15px;
            border: none;
            background-color: #28a745;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
              background-color: #218838;
            }
          }
        }
      }

      .study-contents-class-meta:hover {
        background-color: $pri15;
        // padding: 1rem;
        // scale: 1.1;
      }

      &-subjects {
        &-list {
          // background-color: $grey4;
          background-color: white;
          padding: 1rem 2rem;
          margin: 1rem 0;
          border-radius: 1rem;
          .subjects-list-item {
            margin: 1rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-buttons > * {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}
