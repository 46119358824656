@import "../../index.scss";

.custdrop {
  margin-bottom: 1rem;
  &-label {
    text-align: left;
    font-size: 1.4rem;
    color: $grey2;
    font-weight: 500;
  }
}
.rs-picker-select {
  //   border: none !important;
}
.rs-picker-select .rs-picker-toggle {
  width: 100%;
  border: 1px solid $primary;
  padding: 1.5rem !important;
  text-align: left !important;
  border-radius: 1rem;
  font-size: 1.5rem;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  line-height: 1.5rem;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
}
.rs-picker-toggle {
  padding: 2rem 0;
}

// Adjust the dropdown menu styling if necessary
.rs-picker-select .rs-picker-toggle + .rs-picker-menu {
  border-radius: 1rem;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  //   z-index: 10000;
}
.rs-picker-toggle-value {
  color: black !important;
}

.rs-stack-item {
  & svg {
    margin-top: 6px;
  }
}

.rs-picker-menu {
  z-index: 1000;
  border: 1px solid $primary;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

// .custom-dropdown-menu {
//   width: inherit;
//   // width: calc(
//   //   100% - 10px
//   // ); // Making the dropdown menu slightly narrower than the dropdown
//   // max-width: calc(
//   //   100% - 50px
//   // ); // Ensuring it doesn't exceed the calculated width
//   overflow: hidden; // Optional: Adjust as needed to manage content overflow
// }
