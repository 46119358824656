.idCardPhotoUpload {
  .form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
      text-align: center;
      color: #333;
      margin-bottom: 20px;
    }

    h6 {
      margin-bottom: 2rem;
      color: #ef5038;
    }

    input,
    select,
    button {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 16px;
    }

    button {
      background-color: #0056b3;
      color: white;
      border: none;
      cursor: pointer;
      &:disabled {
        background-color: #ccc;
      }
    }

    & .student-verified {
      color: rgb(0, 117, 0);
      font-size: 2rem;
      font-weight: 600;
    }

    .preview {
      margin-bottom: 15px;
      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }

    .error {
      color: #d9534f;
      margin-bottom: 15px;
    }
  }
}

// .idCardPhotoUpload {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;

//   .form {
//     width: 100%;
//     max-width: 400px;
//     background: #f2f2f2;
//     padding: 20px;
//     border-radius: 8px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

//     h2 {
//       margin-bottom: 20px;
//       text-align: center;
//     }

//     input[type="text"] {
//       width: 100%;
//       padding: 10px;
//       margin-bottom: 10px;
//       border-radius: 5px;
//       border: 1px solid #ccc;
//     }

//     input[type="file"] {
//       width: 100%;
//       margin-bottom: 10px;
//     }

//     .preview {
//       margin-bottom: 10px;
//       img {
//         width: 100%;
//         height: auto;
//         border-radius: 5px;
//       }
//     }

//     button {
//       width: 100%;
//       padding: 10px;
//       border: none;
//       background-color: #007bff;
//       color: white;
//       border-radius: 5px;
//       cursor: pointer;
//       &:disabled {
//         background-color: #ccc;
//       }
//     }
//   }
// }
