@import "../../index.scss";

.admin {
  display: flex;

  // &-sidebar {
  //   width: 22%;
  // }

  &-content {
    width: 100%;
  }
}
