@import "../../index.scss";

.ua {
  padding: 2rem;
  &-header {
    margin-bottom: 2rem;
    &-info {
      display: flex;
      justify-content: space-between;

      padding: 2rem 0;

      &-title {
        @include heading;
      }
    }

    &-filters {
      display: flex;
      justify-content: space-between;

      &-label {
        text-align: left;
        color: $primary;
      }

      &-search {
        width: 60%;
      }
      &-roles {
        width: 30%;
      }
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    &-list {
      width: 40%;
      text-align: left;

      &-header {
        background-color: $grey4;
        padding: 1rem;
        text-align: left;
        display: flex;
        border-bottom: 1px solid #ccc;
        font-size: 1.6rem;
        font-weight: 600;
      }

      &-card {
        @include text;
        display: flex;
        padding: 1rem;
        border-bottom: 1px solid #ccc;

        &:hover {
          background-color: $pri15;
        }
      }
      & .header-id,
      & .row-id {
        width: 15%;
      }

      & .header-name,
      & .row-name {
        width: 50%;
      }

      & .header-role,
      & .row-role {
        width: 35%;
      }
    }

    &-details {
      width: 58%;
    }
  }
}

.uf {
  //   background-color: $primary;
  padding-bottom: 2rem;
  border: 1px solid grey;
  border-radius: 0 0 2rem 2rem;

  &-header {
    padding: 1.5rem;
    background-color: $primary;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    border-radius: 0 0 2rem 2rem;

    &-name {
      @include textbig;
      font-weight: 700;
      color: white;
    }

    & img {
      height: 4rem;
      width: auto;
      cursor: pointer;
    }
  }

  &-form {
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-visit {
      width: 100%;
      display: flex;
      justify-content: end;
      margin-bottom: 1rem;
    }

    &-field {
      width: 48%;
      text-align: left;
      margin-bottom: 2rem;
    }

    // &  input {
    //   margin: 1rem;
    //   width: 45%;
    //   padding: 1rem;
    //   border: 1px solid $primary;
    //   @include shadowBlue;
    //   display: block;
    // }
  }
}

.add-user-form {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: scroll;

  &-title {
    @include heading;
    text-align: center;
  }

  & input {
    margin-bottom: 1rem;
  }

  & button {
    margin: 2rem auto;
    margin-left: auto;
    display: inline;
    align-self: center;
  }
}

.rs-input {
  border: 2px solid $primary !important;
  padding: 1.1rem !important;
  z-index: 3000;
  //   @include shadow;
}
