@import "../../index.scss";

.login {
  @include Poppins;
  font-size: 1.6rem;
  min-height: 70vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2148c0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &-form {
    background-color: rgba(255, 255, 255, 0.19);
    padding: 4rem;

    &-header {
      @include heading;
      font-weight: 700;
    }

    & input {
      padding: 1.5rem;
      min-width: 35rem;
      border: 1px solid white;
      color: black;

      display: block;
      margin: 2rem 0;
      background-color: rgba(255, 255, 255, 0.86);
    }
    & button {
      width: 100%;
    }

    &-forgot {
      text-align: right;
      color: white;
      margin: 1rem 0;
      cursor: pointer;
    }

    &-nouser {
      margin-top: 4rem;
    }
  }
}

.resetPswd {
  color: black;

  & h3 {
    margin: 2rem 0;
  }

  & button {
    margin: 2rem 0;
  }
}
