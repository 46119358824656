@import "../../index.scss";

.stdash {
  padding: 20px;
  min-height: 60vh;
  background: #f5f5f5; // Light background for the dashboard

  &-contents {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;

    &-info,
    &-classes,
    &-ancmnts,
    &-exam {
      background: #ffffff; // White background for content blocks
      border-radius: 10px; // Rounded corners for the content blocks
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      padding: 20px;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px); // Slight lift effect on hover
      }
    }

    &-info {
      width: 100%; // Full width on smaller screens
      max-width: 350px; // Limit width on larger screens
      text-align: center; // Center the text for a cleaner look

      &-brand {
        display: flex;
        // flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
        background-color: $primary;
        padding: 1rem 0;
        border-radius: 1rem;

        div {
          color: white;
          font-weight: 600;
          font-size: 1.8rem;
        }

        img {
          width: 5rem; // Adjust logo size
          height: auto;
          border-radius: 50%;
        }
      }

      &-name {
        margin-top: 20px;
        h3 {
          font-weight: bold;
          color: $secondary; // Use secondary color from your theme
        }
      }

      .student-photo {
        margin-top: 20px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
      }

      &-details {
        text-align: left;
      }
    }

    &-classes,
    &-ancmnts,
    &-exam {
      flex: 1; // Take available space
      min-width: 250px; // Minimum width for readability
      max-height: 60vh;
      overflow: scroll;

      &-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 15px;
        color: $primary; // Use a primary color for titles
      }
    }

    &-classes {
      &-data {
        .classCard {
          padding: 10px;
          background-color: #e3f2fd;
          border-radius: 6px;
          margin-bottom: 10px;
          font-size: 14px;
          color: #333;

          &:last-child {
            margin-bottom: 0;
          }

          &-time {
            font-size: 1.6rem;
            font-weight: 600;
          }
          &-subject {
            font-size: 1.6rem;

            color: $grey1;
          }
          &-teacher {
            font-size: 1.6rem;
          }
          &-buttons {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            & > * {
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
}

.stdash-contents-info-details {
  max-width: 800px;
  // margin: auto;
  // padding: 20px;
  // background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .info-container {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;

    .info-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      // padding: 10px;
      border-radius: 5px;

      &-card {
        background-color: #eef2f7;
        width: 48%;
        text-align: center;
        border-radius: 0.5rem;

        &-header {
          font-weight: 600;
          color: $grey2;
        }
      }

      &-cardfull {
        background-color: #eef2f7;
        width: 100%;
        text-align: center;

        &-header {
          font-weight: 600;
          color: $grey2;
        }
      }

      .info-group-header {
        font-weight: bold;
        color: #2c3e50;
        width: 40%;
      }

      .info-group-info {
        width: 60%;
        text-align: right;
        font-size: 16px;
      }
    }
  }

  h1 {
    font-size: 26px;
    color: #2c3e50;
    margin-bottom: 20px;
  }
}
