.change-password {
  width: 100%;
  margin: auto;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: white;

  &-title {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    button {
      background-color: #4a90e2;
      color: white;
      border: none;
      padding: 10px 15px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: darken(#4a90e2, 10%);
      }
    }
  }

  .error-message {
    color: #ff6b6b;
  }

  .success-message {
    color: #28a745;
  }
}
