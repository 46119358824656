@import "../../index.scss";

.roles {
  padding: 2rem;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    &-name {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: left;
    }

    &-info {
      width: 50%;
      display: flex;
      justify-content: flex-end;

      &-card {
        margin-right: 2rem;
        background-color: #ffffff;
        padding: 1rem 2rem;
        @include shadowBlue;
        border-radius: 0.5rem;

        &-title {
          color: $grey1;
        }

        &-number {
          font-size: 3rem;
          text-align: left;
          font-weight: 700;
        }
      }
    }
  }
  &-contents {
    display: flex;
    justify-content: space-between;

    &-list {
      width: 36%;
    }
    &-details {
      width: 60%;

      &-noselection {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
