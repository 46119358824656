@import "../../index.scss";

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 0 20px;
  @include shadowBlue;

  &-logo {
    height: 65px;
    width: auto;
    // background-color: red;
    // width: 25%;
    display: flex;

    & img {
      height: 100%;
      width: auto;
    }
    @include respond(phone) {
      display: none;
    }
  }

  &-contents {
    background-color: $primary;
    // min-width: 70%;
    border-radius: 2.5rem 0;
    padding: 1rem;
    display: flex;
    align-items: center;

    .topbar-item {
      position: relative;
      padding: 10px 15px;
      margin: 0 10px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: white;
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

      &:hover {
        // background-color: $secondary;
        // color: white;
        background-color: white;
        color: $primary;
        border-radius: 1.5rem 0;
      }

      .dropdown-icon {
        margin-left: 5px;
      }

      // Dropdown menu styling
      .dropdown-menu {
        background-color: white;
        text-align: left;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: max-content;
        background-color: $primary;
        // border: 1px solid white;
        padding: 10px;
        z-index: 200;
        display: none;
        @include shadowBlue;

        a {
          display: block;
          color: white;
          text-decoration: none;
          padding: 5px 10px;
          position: relative;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

          // Animate border sliding in from left to right
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3px;
            width: 0%;
            background-color: white;
            transition: width 0.3s ease-in-out;
          }

          // On hover, the border expands to the full width and text color changes
          &:hover {
            background-color: white;
            color: $grey1;

            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &-user {
    & img {
      height: 5rem;
      width: auto;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .topbar-item:hover .dropdown-menu {
    display: block;
  }
}
