@import "../../index.scss";

.download {
  min-height: 50vh;
  margin: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border-radius: 1.5rem;
  @include Poppins;

  @include respond(phone) {
    margin: 5rem 1.5rem;
  }

  &-content {
    width: 100%;
    padding: 5rem;
    display: flex;

    @include respond(phone) {
      flex-direction: column;
      padding: 1.5rem;
    }

    &-left {
      width: 50%;
      display: flex;
      align-items: center;
      @include respond(phone) {
        width: 100%;
      }

      &-heading {
        @include heading;
        color: white;
      }
    }

    &-right {
      width: 50%;

      @include respond(phone) {
        width: 100%;
        margin-top: 3rem;
      }

      &-verified {
        background-color: white;
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        * {
          width: 60%;
          margin: 2rem 0;
        }
      }
      &-unverified {
        background-color: white;
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-msg {
          @include heading;
          color: $grey1;
          margin-bottom: 2rem;
        }
        & input {
          width: 50%;
          padding: 1.6rem;
          margin: 3rem;
          border: 1px solid $primary;

          @include respond(phone) {
            width: 80%;
          }
        }
      }
    }
  }
}
