@import "../../index.scss";

.textField {
  width: 100%;
  border: 1px solid $primary;
  padding: 1.3rem 1.5rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &-label {
    color: $primary;
    font-size: 1.3rem;
    color: $grey3;
  }
}
