@import "../../index.scss";

.contact {
  padding: 5rem;
  @include Poppins;

  @include respond(phone) {
    padding: 5rem 1.5rem;
  }

  &-card {
    display: flex;
    width: 100%;
    border-radius: 1.5rem;
    @include shadow;

    @include respond(phone) {
      flex-direction: column;
    }

    &-left {
      width: 70%;

      @include respond(phone) {
        width: 100%;
      }

      &-heading {
        margin: 2rem;
        @include heading;

        @include respond(phone) {
          margin: 3rem;
        }
      }

      &-redirect {
        margin: 2rem;
        width: 70%;
        margin: 0 auto;
        @include textbig;

        &-button {
          margin: 3rem;
          margin-bottom: 5rem;

          @include respond(phone) {
          }
        }
      }

      &-content {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        @include respond(phone) {
          flex-direction: column;
        }

        &-card {
          border-radius: 2rem 0;
          background-color: $primary;
          padding: 2rem;
          text-align: left;
          width: 40%;
          @include shadow;

          @include respond(phone) {
            margin: 2rem;
            width: 90%;
            padding: 1rem;
          }
          &-title {
            @include textbig;
            font-weight: 600;
            display: flex;
            align-items: center;
            color: white;
            margin-bottom: 2rem;
          }

          &-option {
            @include text;
            font-weight: 600;
            color: $grey4;
            margin: 1rem;
          }
        }
      }
    }

    &-right {
      padding: 4rem;
      width: 40%;
      background-color: $primary;
      border-radius: 1.5rem;

      @include respond(phone) {
        margin-top: 4rem;
        width: 100%;
        padding: 1rem;
      }

      & iframe {
        width: 100%;
        height: 70vh;
        border-radius: 1.5rem;
        @include shadow;

        @include respond(phone) {
          height: 50vh;
        }
      }
    }
  }
}
