@import "../../index.scss";

.datepicker {
  // position: relative;
  cursor: pointer;
  border: 1px solid $primary;
  border-radius: 1rem;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: white;

  &-invalid {
    border: 5px solid red !important;
  }

  &-label {
    font-size: 1.4rem;
    color: $grey3;
  }

  &-unselected {
    cursor: pointer;
    border-radius: 1rem;
    font-size: 16px;
    border: none;
    width: 100%;
    padding: 1.3rem;
    color: $grey2;
    // box-sizing: border-box;
  }
  &-selected {
    cursor: pointer;
    border-radius: 1rem;
    font-size: 16px;
    border: none;
    width: 100%;
    padding: 1.3rem;
    color: black;
    // box-sizing: border-box;
  }

  & input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    background: url("../../assets/images/calendar.png") no-repeat;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
