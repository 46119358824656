@import "../../index.scss";

.slips {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f9f9f9; // Light grey background for contrast

  &-title {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #343a40; // Dark grey for a professional tone
    text-align: center;
  }

  &-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @include respond(phone) {
      flex-direction: column;
    }

    &-dd {
      min-width: 200px;
      margin-right: 2rem;

      @include respond(phone) {
        width: 80%;
      }
    }
  }
}
