@import "../../index.scss";

.sstu {
  padding: 20px;
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: space-between;
  @include Poppins;

  &-heading {
    @include heading;
  }

  &-header {
    width: 100%;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-warn {
      background-color: $danger;
      font-weight: 600;
      color: white;
      padding: 1.5rem;
      display: flex;
      border-radius: 1rem 0 1rem 0;
      border-left: 0.5rem solid $primary;
      border-right: 0.5rem solid $primary;
      height: fit-content;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      & > * {
        margin-bottom: 2rem;
      }
    }
  }
  &-filters {
    border-radius: 1rem;
    height: 70vh;
    width: 25%;
    padding: 1rem;
    background-color: $pri15;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-label {
      @include text;
      text-align: left;
      margin-left: 1rem;
      color: $primary;
    }

    &-search {
      width: 100%;

      & input {
        width: 100%;
        padding: 1rem 1.5rem;
        border: 2px solid $primary;
        border-radius: 2.5rem;
      }
    }
    &-datepicker {
      width: 100%;
    }
    &-hostel {
      width: 100%;
    }

    &-class {
      width: 100%;
    }
    &-mess {
      width: 100%;
    }

    &-transport {
      width: 100%;
    }
  }

  &-filters2 {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;

    & > div {
      width: 17%;
      margin-left: 2rem;
    }
  }

  &-table {
    width: 73%;

    &-header {
      display: flex;
      font-size: 1.6rem;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid #ccc;
      background-color: #f4f4f4;
      font-weight: bold;
      text-align: left;

      > div {
        padding: 5px;
      }

      .header-id,
      .row-id {
        width: 15%;
      }
      .header-name,
      .row-name {
        width: 25%;
      }
      .header-whatsapp,
      .row-whatsapp {
        width: 20%;
      }
      .header-class,
      .row-class {
        width: 20%;
      }
      .header-timestamp,
      .row-timestamp {
        width: 20%;
      }
      .header-timestamp {
        cursor: pointer;
      }
    }

    &-footer {
      background-color: #f4f4f4;
      font-weight: 600;
      text-align: left;
      @include text;
      padding: 1rem;
    }
  }
}
