@import "../../index.scss";

.hosteld {
  padding: 5rem;
  background-color: #f2f2f2;
  @include Poppins;

  .hosteld-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: $primary;
    padding: 1rem 2rem;
    @include shadowBlue;
    // border-radius: 0.5rem;

    h1 {
      font-size: 2em;
      color: $grey4;
    }

    & img {
      width: auto;
      height: 5rem;
      cursor: pointer;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    &-cards {
      display: flex;

      width: 60%;
    }

    &-card {
      margin-right: 2rem;
      background-color: #ffffff;
      padding: 1rem 2rem;
      @include shadowBlue;
      border-radius: 0.5rem;

      &-title {
        color: $grey1;
      }

      &-number {
        font-size: 3rem;
        text-align: left;
        font-weight: 700;
      }
    }
  }

  &-floors {
    // .fplan {
    //   margin-bottom: 30px;

    //   .fplan-header {
    //     h2 {
    //       font-size: 1.5em;
    //       color: #444;
    //     }
    //   }

    //   .fplan-rooms {
    //     margin-top: 10px;
    //     .room {
    //       background-color: #f0f0f0;
    //       padding: 10px;
    //       border-radius: 8px;
    //       margin-bottom: 10px;

    //       h3 {
    //         font-size: 1.2em;
    //       }

    //       .beds {
    //         margin-top: 5px;
    //         p {
    //           font-size: 1em;
    //           margin: 5px 0;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

// HostelDetails.scss

.fplan {
  margin-bottom: 30px;
  background-color: white;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $pri15;
    padding: 1.5rem;
    border-radius: 0 0 1rem 1rem;

    h2 {
      font-size: 1.5em;
      color: #444;
    }

    & img {
      height: auto;
      width: 5rem;
      cursor: pointer;
    }
  }

  &-info {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-card {
      display: flex;
      align-items: center;
      @include shadowBlue;
      padding: 0.5rem 1.5rem;
      border-radius: 0.5rem;

      &-title {
        color: $grey1;
      }
      &-number {
        margin-left: 2rem;
        font-size: 3rem;
        font-weight: 700;
      }
    }
  }

  &-rooms {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;

    .room {
      width: 30%;
      margin: 1rem;

      border-radius: 1rem;

      //   margin-bottom: 15px;
      //   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      @include shadowBlue;

      &-header {
        padding: 1rem;
        background-color: #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0 0 1.5rem 1.5rem;
        cursor: pointer;

        & img {
          width: 5rem;
          height: auto;
        }
      }

      h3 {
        font-size: 1.2em;
        margin-bottom: 10px;
      }

      .beds {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 2rem 1rem;

        .bed {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          margin: 5px;
          border-radius: 50%;
          color: white;
          font-weight: bold;

          &.vacant {
            background-color: #4caf50; // Green for vacant
          }

          &.occupied {
            cursor: pointer;
            background-color: #9e9e9e; // Grey for occupied
          }
        }
      }
    }
  }
}

.addfloor,
.addrooms {
  &-title {
    font-size: 3rem;
    font-weight: 600;
    color: $grey1;
  }
  & > * {
    text-align: left;
    margin-bottom: 2rem;
  }
}

.bedDetails {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
  background-color: #f9f9f9;

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin: 5px 0;
    font-size: 16px;
  }
}
