@import "../../index.scss";

.feesinfo {
  padding: 30px;
  background-color: #f4f5f7;

  &-header {
    &-title {
      text-align: left;
      @include heading;
      font-weight: 800;
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;

    &-overdue {
      width: 20%;
      background-color: white;
      padding: 2rem;
      text-align: left;

      &-title {
        @include textbig;
        font-weight: 700;
        color: $danger;
      }

      &-cards {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;

        &-card {
          background-color: $pri15;
          padding: 1rem;
          width: 100%;
          margin: 1rem auto;
          @include shadow;
          border-radius: 1rem 0 1rem;

          & h3 {
            color: $primary;
          }
        }
      }
    }
  }

  &-installments {
    width: 79%;
    background-color: $pri15;
    padding: 1rem;

    &-filters {
      display: flex;
      flex-direction: column;
      &-row1 {
        width: 98%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-search {
          width: 60%;
        }
      }
      &-row2 {
        display: flex;
        justify-content: space-between;
        width: 98%;
        margin: 10px auto;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        &-inst {
          width: 35%;
        }
        &-session {
          width: 15%;
        }

        &-status {
          width: 15%;
        }
        &-type {
          width: 15%;
        }
        &-type {
          width: 15%;
        }
      }
    }
  }

  .email-section {
    margin: 30px 0;
    text-align: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #dee2e6;

    textarea {
      width: 100%;
      height: 120px;
      padding: 15px;
      border: 1px solid #ced4da;
      border-radius: 5px;
      resize: none;
      margin-bottom: 15px;
    }

    button {
      padding: 10px 25px;
      background-color: #007bff;
      color: #fff;
      font-weight: bold;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.installments-table-wrapper {
  margin-top: 30px;
  background-color: $primary;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;
}

.installments-table {
  width: 100%;
  border-collapse: separate;

  th,
  td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #dee2e6;
  }

  th {
    background-color: #f8f9fa;
    color: #495057;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 2rem;
  }

  td {
    background-color: #ffffff;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #f1f3f5;
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
  }
}

.installments-summary {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  font-weight: bold;

  p {
    margin: 0;
  }

  p:first-child {
    color: #fdfdfd;
  }

  p:last-child {
    color: #ffffff;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    padding: 8px 15px;
    margin: 0 5px;
    border: 1px solid #dee2e6;
    background-color: #f8f9fa;
    color: #007bff;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #007bff;
      color: #ffffff;
    }

    &:disabled {
      background-color: #dee2e6;
      color: #6c757d;
      cursor: not-allowed;
    }
  }
}
