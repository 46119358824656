@import "../../index.scss";

.student-results {
  margin: 2rem;
  text-align: left;
  &-title {
    @include heading;
  }
}

.student-results-container {
  min-height: 70vh;
  // max-width: 960px;
  // margin: 2rem;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;

  h3 {
    background-color: #007bff;
    color: white;
    padding: 8px;
    border-radius: 5px;
  }

  .exam-section {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow-x: scroll;

    table {
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: #f0f0f0;
      }
    }
  }
}
