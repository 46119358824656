@import "../../index.scss";

.dropdown {
  @include Poppins;
  position: relative;
  width: 100%;
  border: 1px solid $primary;
  background-color: rgb(252, 252, 252);
  padding: 1.2rem 1.5rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  // margin-bottom: 15px;

  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &-invalid {
    border: 5px solid red !important;
  }

  &-label {
    text-align: left;
    font-size: 1.5rem;
    color: "#6c6c6c";
    font-weight: 500;
    // color: $grey3;
  }

  .selected-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $grey2;
  }
  .arrow {
    transition: transform 0.3s ease;
    &.open {
      transform: rotate(180deg);
    }
  }
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid $primary;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1002;
  }
  .option {
    padding: 10px;
    color: black;
    cursor: pointer;
    &:hover {
      background-color: $pri15;
    }
  }
}
