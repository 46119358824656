@import "../../index.scss";

.schSettings {
  margin: 2rem auto;
  padding: 2rem 5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  &-title {
    @include heading;
    font-weight: 700;
    color: #333;
    text-align: left;
    margin-bottom: 2rem;
  }

  &-form {
    background-color: $grey4;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    border-radius: 1rem;
    @include shadowBlue;

    &-input {
      width: 32%;
    }

    label {
      display: block;
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: $grey1;
      text-align: left;
    }

    input[type="number"],
    input[type="email"] {
      width: 100%;
      padding: 10px;
      font-size: 1.6rem;
      border: 2px solid #ccc;
      border-radius: 1rem;
      box-sizing: border-box;

      &:focus {
        border-color: #0056b3;
        outline: none;
      }
    }

    button {
      padding: 10px 15px;
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      background-color: $success;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  &-history {
    margin-top: 4rem;

    &-title {
      @include textbig;
      font-weight: 700;
      text-align: left;
    }

    &-search {
      width: 40%;

      & input {
        width: 100%;
        padding: 1rem;
        border-radius: 2rem;
        border: 1px solid $primary;
        @include shadowBlue;
      }
    }
  }

  .approvals-list {
    margin-top: 20px;
    table {
      width: 100%;
      border-collapse: collapse;
      th,
      td {
        border: 1px solid #ccc;
        padding: 8px;
        text-align: left;
      }
      th {
        background-color: #f4f4f4;
      }
    }
  }
}
