@import "../../index.scss";

.leaves-list-container {
  @include respond(phone) {
    width: 100;
    overflow-x: scroll;
  }
  .leaves-list {
    @include respond(phone) {
      width: 100;
      overflow-x: scroll;
    }

    .list-header,
    .list-row {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 8px;
      @include respond(phone) {
        min-width: 1200px;
      }
    }

    .list-header {
      background-color: #f0f2f5;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .header-item,
    .row-item {
      text-align: left;
      padding: 0 10px;
      width: fit-content;

      &.type {
        flex: 1;
        text-transform: capitalize;
      }
      &.start-date,
      &.end-date {
        flex: 2;
      }
      &.approver {
        flex: 2;
      }
      &.reason {
        flex: 5;
      }
      &.remarks {
        flex: 5;
      }
      &.status {
        flex: 1;
      }
      &.statusred {
        color: $danger;
        flex: 1;
      }
      &.statusgreen {
        color: $success;
        flex: 1;
      }
      &.action {
        flex: 1;
        justify-content: center;
      }
    }

    .action {
      button {
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        background-color: #ff4d4f;
        color: white;
        cursor: pointer;

        &:hover {
          background-color: #ff7875;
        }
      }
    }
  }

  .no-leaves {
    text-align: center;
    padding: 20px;
    color: #666;
  }
}
