@import "../../index.scss";

.layout {
  position: relative;

  .server-down {
    background-color: red;
    color: white;
    padding: 1rem 0;
  }
}

////////////////////////////////
/// Styles for the Slider.

.menu {
  z-index: 200;
  @include Poppins;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  cursor: pointer;

  &-header {
    @include hero;
    background-color: $primary;
    height: 100%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 1rem 1rem 0;

    &-content {
      height: 40%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  &-contents {
    width: 100%;
    // position: relative;
    margin: 3rem 8rem;

    @include respond(phone) {
      margin: 3rem;
    }
    &-cross {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      & svg {
        width: 6rem;
        height: auto;

        @include respond(tablet) {
          width: 5rem;
        }
        @include respond(phone) {
          width: 3rem;
        }
      }
    }
    &-header {
      text-transform: uppercase;
      font-size: 5vw;
      color: $primary;
      @include Monoton;

      @include respond(phone) {
        margin-top: 3rem;
        font-size: 9vw;
      }
    }

    &-links {
      margin: 2rem;
      display: flex;
      flex-direction: column;

      &-link {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 5vw;
        color: white;

        @include respond(phone) {
          font-size: 9vw;
          margin: 1.5rem 0;
        }
      }

      & a {
        text-decoration: none;
      }

      &-link:hover {
        color: $primary;
      }
    }
    &-login {
      margin: 5rem auto;
      color: $primary;
      font-size: 2rem;
      background-color: white;
      width: 40%;
      padding: 1rem 2rem;
      border-radius: 1rem;
    }
  }
}

.dummyheader {
  width: 100%;
  height: 10vh;
  background-color: $primary;
}

.halfpage {
  padding: 2rem;
  min-height: 60vh;

  @include respond(phone) {
    padding: 2rem 1rem;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @include respond(phone) {
      flex-direction: column;

      padding: 1rem;
    }

    &-card {
      margin-right: 2rem;
      background-color: #ffffff;
      padding: 0.5rem 4rem;
      @include shadowBlue;
      border-radius: 2rem 0 2rem 0;
      display: flex;
      align-items: center;

      @include respond(phone) {
        display: none;
      }

      &-title {
        color: $grey1;
        margin-right: 2rem;
      }

      &-number {
        font-size: 3rem;
        text-align: left;
        font-weight: 700;
      }
    }

    &-name {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: left;
    }

    &-filters {
      width: 60%;
      // width: max-content;
      display: flex;
      justify-content: end;
      // align-items: center;
      text-align: left;

      @include respond(phone) {
        width: 100%;
        flex-direction: column-reverse;
        align-self: flex-end;
      }

      &-filter {
        width: 30%;
        margin-left: 2rem;

        @include respond(phone) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  &-subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @include respond(phone) {
      flex-direction: column;
      width: 100%;
      padding: 1rem;
    }
  }
  &-contents {
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
      flex-direction: column;
    }

    &-list {
      width: 36%;

      @include respond(phone) {
        width: 100%;
      }
    }
    &-details {
      width: 60%;

      @include respond(phone) {
        width: 100%;
      }

      &-noselection {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          width: 60%;
          height: auto;
        }
      }
    }
  }
}

.halfAdd {
  // text-align: left;
  &-title {
    font-size: 3rem;
    font-weight: 600;
    color: $grey1;
    margin-bottom: 2rem;
  }

  & textarea {
    padding: 1rem;
    width: 100%;
    border-radius: 1rem;
    margin: 1.5rem 0;
    border: 1px solid $primary;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.dc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // text-align: right;
    margin-bottom: 2rem;

    &-info {
      background-color: $success;
      padding: 1rem;
      border-radius: 1rem;
      color: white;
    }

    &-cta {
      margin-left: auto;
    }
  }

  &-visit {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2rem;
  }

  &-input {
    margin-bottom: 1rem;
    width: 48%;
  }

  &-text {
    width: 48%;
    margin: 1rem 0;
    &-label {
      font-weight: 600;
    }
  }
  &-image {
    width: 98%;
    margin: 1rem 0;

    & img {
      width: 100%;
      height: auto;
    }
    &-label {
      font-weight: 600;
    }
  }
  &-textfull {
    width: 100%;
    margin: 1rem 0;
    &-label {
      font-weight: 600;
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}

input[type="file"] {
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $primary;
  @include shadowBlue;
}
