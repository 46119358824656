.bulkSalaryUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;

  h2 {
    color: #333;
    margin-bottom: 20px;
  }

  .downloadButton,
  .uploadButton {
    margin: 10px 0;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #003d7a;
    }
  }

  input[type="file"] {
    margin: 20px 0;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .instructions {
    margin-top: 30px;
    text-align: left;
    width: 80%;

    h3 {
      color: #333;
      margin-bottom: 10px;
    }

    ul {
      list-style-type: disc;
      padding-left: 20px;

      li {
        margin-bottom: 5px;
        line-height: 1.5;
        color: #555;
      }
    }
  }
}
