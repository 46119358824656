@import "../../index.scss";

.stfeedetails {
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  @include shadow;

  @include respond(phone) {
    padding: 0.5rem;
  }

  &-meta {
    margin-bottom: 3rem;
    // background: $pri15;
    // padding: 1.5rem 2rem;
    // border-radius: 0 2rem 0 2rem;
    // box-shadow: 0 4px 6px $black25;

    &-title {
      margin-bottom: 1rem;
      @include textbig;
      color: $primary;
      font-weight: 600;
    }

    &-content {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      @include respond(phone) {
        flex-direction: column;
        width: 100%;
      }

      &-card {
        background-color: $secondary;
        border-left: 3px solid $primary;
        border-bottom: 3px solid rgb(200, 31, 31);
        box-shadow: 4px 4px 6px $black25;

        width: 23%;
        padding: 1rem;
        border-radius: 0 2rem;

        @include respond(phone) {
          width: 100%;
        }

        display: flex;
        flex-direction: column;

        &-text {
          font-size: 2.4rem;
          font-weight: 600;
          color: rgb(231, 231, 231);
        }
        &-amt {
          font-size: 2.4rem;
          font-weight: 600;
          color: white;
          align-self: flex-end;
        }
      }
    }

    // Responsive adjustments
    @include respond(phone) {
      padding: 10px;

      > div {
        margin-bottom: 10px;
      }

      > div:first-child {
        font-size: $mheading;
      }

      > div:not(:first-child) {
        padding: 8px;
      }
    }
  }

  .fee-summary,
  .installments-section {
    // background: #f8f9fa;
    // border: 1px solid #dee2e6;
    // border-radius: 5px;
    // padding: 20px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    @include respond(phone) {
      text-align: center;
      padding: 1rem;
    }
  }

  h2,
  h3 {
    color: #495057;
  }

  .installment {
    margin-top: 15px;

    transition: all 0.3s ease;

    // &:last-child {
    //   border-bottom: none;
    // }

    .installment-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 10px;
      // background: #e9ecef;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      border-bottom: 1px solid $secondary;
      border-right: 1px solid #dee2e6;
      border-radius: 2rem;

      &:hover {
        background-color: $pri15;
      }

      &-info {
        width: 90%;
        justify-content: space-between;
        display: flex;

        @include respond(phone) {
          flex-direction: column;
          align-items: flex-start;
        }

        &-paid {
          color: $success;
          font-weight: 600;
        }
        &-unpaid {
          color: red;
          font-weight: 600;
        }
      }

      &-name {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: #343a40;
        text-align: left;

        @include respond(phone) {
          font-size: 1.5rem;
        }
      }

      span {
        font-size: 22px;
        color: #343a40;
      }
    }

    .installment-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin-top: 10px;

      @include respond(phone) {
        flex-direction: column;
      }

      p {
        margin: 0;
        font-size: 16px;
        color: #343a40;

        &:before {
          content: attr(data-label);
          font-weight: bold;
          margin-right: 5px;
        }
      }

      .detail {
        margin: 10px 0;
        display: flex;
        flex-direction: column;

        @include respond(phone) {
          text-align: center;
        }

        .title {
          font-size: 14px;
          color: #6c757d;
          margin-bottom: 4px;
        }

        p {
          font-size: 16px;
          color: #212529;
          margin: 0;
        }
        & .latefee {
          color: red;
        }
      }

      .final-fee {
        font-weight: bold;
        color: #007bff; // A blue color to highlight the final fee
      }

      .amount,
      .due-date {
        font-weight: bold;
      }

      .status {
        color: #dc3545;
        font-weight: bold;

        &.Paid {
          color: #28a745;
        }
      }
    }
  }
}

/////////////////////////////////////////
/// Receipt Styles
///
/// General Styles for receipt.

.receipt-modal {
  button {
    margin-bottom: 5px;
  }
}
.receipt-cis,
.receipt-kcepl,
.receipt-ccf {
  font-family: Arial, sans-serif;
  color: #333;
  width: 1100px;
  margin: 0 auto;
  // border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: left;

  .receipt-section {
    margin: 20px 0;

    &-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .receipt-table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;

    thead {
      tr {
        padding: 0 5px;
        background-color: $pri15;
        border-bottom: 3px solid black;

        th {
          border: 1px solid rgb(79, 79, 79);
          padding: 0 5px;
        }
      }
    }

    tbody {
      text-align: left;
      width: 100%;

      tr {
        width: 100%;

        .th25 {
          padding: 0 5px;
          width: 25%;
          border: 1px solid rgb(79, 79, 79);
          background-color: $pri15;
        }
        .td25 {
          padding: 0 5px;
          width: 25%;
          border: 1px solid rgb(79, 79, 79);
        }

        .th50 {
          padding: 0 5px;
          width: 50%;
          border: 1px solid rgb(79, 79, 79);
          background-color: $pri15;
        }
        .td50 {
          padding: 0 5px;
          width: 50%;
          border: 1px solid rgb(79, 79, 79);
        }
      }
    }
  }
}

/// Receipt header styles
.receipt-header {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: $primary;

  &-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    img {
      max-width: 80px;
      border-radius: 50%;
    }
  }
  &-text {
    text-align: right;
    &-name {
      font-size: 30px;
      font-weight: 600;
      color: white;
    }
    &-add {
      font-size: 20px;
      font-weight: 500;
      color: rgb(229, 229, 229);
    }
  }
}

/// Receipt Info Styles
.receipt-ccf {
  text-align: left;
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;

    th,
    td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
      color: #333;
    }
  }
}

// Payment Breakdown Styles
.components-info,
.receipt-kcepl .components-info {
  margin: 20px 0;

  h4,
  h3 {
    font-size: 16px;
    color: #444;
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;

    th,
    td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f4f4f4;
      font-weight: bold;
      color: #333;
    }
  }
}

.receipt-compInfo {
  font-size: 16px;
  margin: 20px 0;
}

// Footer Styles
.receipt-footer {
  text-align: center;
  margin-top: 20px;

  p {
    margin: 5px 0;
    font-size: 12px;
    color: #666;
  }
}

.accept-payment {
  padding: 20px;
  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 400px;
  margin: 20px auto;

  h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
  }
  & .info {
    color: $grey1;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .form-group {
    width: 100%;
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 5px;
      color: #666;
      font-size: 16px;
    }

    select,
    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      line-height: 24px;
      display: block;

      &:focus {
        outline: none;
        border-color: #0056b3;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      cursor: pointer;
      background-color: #0056b3;
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        background-color: #004494;
      }

      &:first-child {
        background-color: #ccc;
        color: #333;

        &:hover {
          background-color: #bbb;
        }
      }
    }
  }
}
