@import "../../index.scss";

.formver {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.scholarship {
  padding: 5rem;
  @include Poppins;
  background-color: $pri15;

  @include respond(phone) {
    padding: 1.5rem;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
      & img {
        width: 9rem;
        height: auto;
        border-radius: 50%;

        @include respond(tablet) {
          width: 5rem;
        }
      }
    }

    &-title {
      font-weight: 600;
      font-size: 3.8rem;
      width: 50%;

      @include respond(tablet) {
        font-size: 3rem;
      }
      @include respond(phone) {
        font-size: 2rem;
      }
    }

    &-ams {
      font-size: 4.8rem;
      color: white;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }

  &-contents {
    background-color: $primary;
    padding: 3rem;

    @include respond(phone) {
      padding: 1rem;
    }

    &-info {
      @include textbig;
      font-weight: 700;
      color: rgb(130, 37, 37);
    }

    &-section {
      text-align: left;
      margin: 2rem;

      &-title {
        font-size: 2.8rem;
        font-weight: 600;
        // color: white;

        @include respond(phone) {
          font-size: 2.2rem;
        }
      }

      &-fields {
        padding: 2rem 4rem;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: space-between;

        @include respond(phone) {
          padding: 1rem 0;
        }

        &-field {
          //   margin-left: auto;
          width: 32%;
          // margin-bottom: 2rem;

          @include respond(tablet) {
            width: 48%;
          }
          @include respond(phone) {
            width: 100%;
          }

          & .input-error {
            border: px solid red;
          }

          &-other {
            width: 48%;
            margin-bottom: 2rem;
          }
        }
      }
      //   &-fields::after {
      //     content: "";
      //     flex: auto;
      //   }
    }

    &-seperator {
      border: none;
      border-bottom: 1px solid $grey3;
      width: 90%;
      margin: 0 auto;
    }
  }

  &-error {
    color: red;
    margin-bottom: 2rem;
    font-size: 2.4rem;
  }

  &-button {
  }

  &-text {
    color: white;
  }
}

.input-error {
  border: 5px solid red;
  background-color: red;
}
