@import "../../index.scss";

.filespage {
  font-family: Arial, sans-serif;
  max-width: 800px;
  min-height: 70vh;
  margin: 20px auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  .filespage-header {
    padding: 2rem;
    background-color: $primary;
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &-title {
      @include heading;
      font-weight: bold;
    }

    &-add {
      background-color: white;
      width: fit-content;
      margin: 1.5rem auto;
      padding: 0 3rem;
      border-radius: 1rem;

      button {
        padding: 8px 16px;
        background-color: #4caf50;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #45a049;
        }
      }

      input[type="file"] {
        margin-right: 1rem;
        color: black;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border-bottom: none;
      }

      a {
        color: #0056b3;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
