@import "../../index.scss";

.umarks {
  width: 100%;

  &-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    background-color: $primary;
    width: max-content;
    margin: 2rem auto;
    border-radius: 1.5rem;

    .tab-btn {
      padding: 1.5rem 1.5rem;
      margin: 1rem;
      color: white;
      border: none;
      border-radius: 0.5rem;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: darken($grey4, 10%);
        color: black;
      }

      &.active {
        background-color: $secondary;
        color: $white;
        font-weight: bold;
      }
    }
  }

  &-content {
    width: 80%;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid $grey1;
    border-radius: 5px;
    @include shadowBlue;

    &-sch,
    &-ent {
      width: 100%;

      &-header {
        @include textbig;
        font-weight: 700;
        margin-bottom: 1.5rem;
      }

      &-sample {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;

        button {
          padding: 0.5rem 1rem;
          background-color: $secondary;
          color: $white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: darken($secondary, 10%);
          }
        }
      }

      &-upload {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        input[type="file"] {
          border: 1px solid $grey1;
          padding: 0.5rem;
          border-radius: 5px;
        }
      }
    }
  }

  &-directions {
    margin-top: 2rem;
    padding: 1.5rem;
    text-align: left;
    border-radius: 5px;
    background-color: $white;
    font-size: 0.9rem;
    color: $grey1;
    font-size: 1.6rem;

    h3 {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    ul {
      list-style: inside square;
      padding-left: 1rem;

      li {
        margin-bottom: 0.5rem;
      }
    }

    p {
      margin-top: 1rem;
      font-style: italic;
    }
  }
}

// @import "../../index.scss";

// .umarks {
//   width: 100%;

//   &-content {
//     width: 100%;
//     display: flex;

//     &-sch {
//       padding: 2rem;
//       width: 50%;
//       border-right: 1px dotted $primary;
//       &-header {
//         @include textbig;
//         font-weight: 700;
//         margin: 2rem 0;
//       }

//       &-sample {
//         width: 100%;
//         display: flex;
//         justify-content: flex-end;

//         & button {
//           padding: 1rem;
//           background-color: transparent;
//           border-bottom: 2px solid $primary;
//           margin-left: auto;
//         }
//       }

//       &-upload {
//         display: flex;
//         align-items: center;
//         margin: 2rem 0;
//       }
//     }

//     &-ent {
//       padding: 2rem;
//       width: 50%;

//       &-header {
//         @include textbig;
//         font-weight: 700;
//         margin: 2rem 0;
//       }

//       &-sample {
//         width: 100%;
//         display: flex;
//         justify-content: flex-end;

//         & button {
//           padding: 1rem;
//           background-color: transparent;
//           border-bottom: 2px solid $primary;
//           margin-left: auto;
//         }
//       }

//       &-upload {
//         display: flex;
//         align-items: center;
//         margin: 2rem 0;
//       }
//     }
//   }
// }
