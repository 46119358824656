@import "../../index.scss";

.hdetails {
  width: 100%;
  border-radius: 2rem;
  // background-color: #4585e5ad;
  background-color: $secondary;
  max-height: 60vh;
  overflow-y: scroll;

  &-header {
    padding: 1rem 2rem;

    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2rem;

    &-name {
      font-size: 2rem;
      font-weight: 700;
      background-color: white;
      color: $primary;
      padding: 0.75rem 2rem;
      border-radius: 1rem;
      min-width: 50%;
      text-align: left;
    }

    & img {
      height: 5rem;
      width: auto;
      cursor: pointer;
    }
  }

  &-contents {
    background-color: white;
    padding: 2rem;
    margin: 0 2rem;
    border-radius: 2rem;
  }

  &-footer {
    padding: 2rem;
    margin-bottom: 2rem;
    color: white;
  }
}
