@import "../../index.scss";

.home {
  @include Poppins;

  &-hero {
    padding: 3rem 0;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;

    &-name {
      color: $primary;
      margin-bottom: 2rem;
      @include heading;
      @include respond(phone) {
        margin-top: 2rem;
        margin-bottom: 1rem;
        width: 90%;
      }
    }
    &-title {
      color: white;

      width: 70%;
      margin-bottom: 2rem;
      @include hero;

      @include respond(phone) {
        width: 90%;
      }
    }
    &-text {
      color: $grey3;

      width: 70%;
      margin-bottom: 4rem;
      @include textbig;

      @include respond(phone) {
        width: 90%;
      }
    }
    &-button {
      margin-bottom: 2rem;
    }
  }

  //Intro Section
  &-intro {
    display: flex;
    justify-content: space-between;

    @include respond(phone) {
      flex-direction: column-reverse;
    }

    &-left {
      width: 48%;
      margin-top: 3rem;
      //Below styles can be removed
      //   border-radius: 0rem 4.5rem 0 0;
      //   background: #fff;
      //   box-shadow: 5px 5px 42px 9px rgba(0, 0, 0, 0.25);
      //Till here.

      @include respond(phone) {
        margin-top: 2rem;
        margin-bottom: 5rem;

        // margin: 5rem 0;
        width: 100%;
        height: auto;
      }

      & svg {
        width: 60%;
        height: auto !important;
        filter: drop-shadow(13px 15px 15px rgb(0 0 0 / 0.4));
        // filter: drop-shadow(5px 5px 42px 9px rgba(0, 0, 0, 0.25));

        @include respond(phone) {
          width: 80%;
        }
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5rem;
      width: 48%;
      margin-bottom: 3rem;
      border-radius: 0rem 0rem 0rem 4.5rem;
      background: #fff;
      box-shadow: 5px 5px 42px 9px rgba(0, 0, 0, 0.25);

      @include respond(phone) {
        width: 100%;
        padding: 1.5rem;
        border-radius: 0rem 0rem 4.5rem 4.5rem;
      }

      &-ques {
        margin-bottom: 2rem;
        color: $primary;
        @include heading;

        @include respond(phone) {
          margin: 4rem 0;
        }
      }

      &-ans {
        margin-bottom: 2rem;
        color: $grey2;
        @include textbig;
      }
      &-button {
        @include respond(phone) {
          margin: 4rem 0;
        }
      }
    }
  }

  //Student Section
  &-students {
    padding: 5rem;
    background-color: $primary;
    display: flex;

    @include respond(phone) {
      padding: 1.5rem;
      flex-direction: column;
    }

    &-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(phone) {
        align-items: center;
        width: 100%;
      }

      & hr {
        border: none;
        border-bottom: 15px solid white;
        width: 20%;
        @include respond(phone) {
          width: 40%;
        }
      }

      &-title {
        margin: 2rem 0;
        text-align: left;
        color: white;
        @include heading;
        @include respond(phone) {
          text-align: center;
        }
      }

      &-text {
        text-align: left;

        color: $grey4;
        width: 90%;

        @include textbig;
        @include respond(phone) {
          text-align: center;
          margin-bottom: 2rem;
        }
      }
    }

    &-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(phone) {
        width: 100%;
      }

      &-cards {
        // margin: 3rem 0;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;

        &-card {
          margin: 1rem auto;
          width: 80%;

          @include respond(phone) {
            width: 90%;
            margin: 1rem auto;
          }
        }
      }
    }
  }

  //Admin Section
  &-admins {
    // padding: 5rem;
    display: flex;

    @include respond(phone) {
      //   padding: 1.5rem;
      flex-direction: column-reverse;
    }

    &-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(phone) {
        width: 100%;
        padding: 1.5rem;
      }

      &-cards {
        margin: 3rem 0;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;

        &-card {
          margin: 1rem;
          width: 80%;

          @include respond(phone) {
            width: 90%;
            margin: 1rem auto;
          }
        }
      }
    }

    &-right {
      padding: 5rem;
      width: 50%;
      margin-bottom: 3rem;
      border-radius: 0rem 0rem 0rem 4.5rem;
      background: #fff;
      box-shadow: 5px 5px 42px 9px rgba(0, 0, 0, 0.25);

      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(phone) {
        padding: 1.5rem;
        align-items: center;
        border-radius: 0rem 0rem 4.5rem 4.5rem;
        width: 100%;
      }

      & hr {
        border: none;
        border-bottom: 15px solid $primary;
        width: 20%;
        margin-left: auto;

        @include respond(phone) {
          width: 40%;
          margin: 1rem auto;
        }
      }

      &-title {
        margin: 2rem 0;
        text-align: right;
        color: $primary;
        @include heading;

        @include respond(phone) {
          text-align: center;
        }
      }

      &-text {
        text-align: right;

        color: $grey2;

        width: 90%;
        margin-left: auto;
        @include textbig;

        @include respond(phone) {
          text-align: center;
          margin: 0 auto;
          margin-bottom: 4rem;
        }
      }
    }
  }

  &-visitors {
    padding: 5rem;

    @include respond(phone) {
      padding: 1.5rem;
    }

    &-ques {
      color: $primary;
      margin-bottom: 2rem;
      @include heading;
    }

    &-ans {
      width: 80%;
      margin: 0 auto;
      color: $grey2;
      @include textbig;

      @include respond(phone) {
        width: 100%;
      }
    }

    &-buttons {
      width: 30%;
      display: flex;
      justify-content: space-between;
      margin: 2rem auto;

      @include respond(phone) {
        flex-direction: column;
        width: 60%;
      }

      & * {
        margin: 1.5rem;
        z-index: 50;
      }
    }
  }
}

.featureCard {
  background-color: white;
  display: flex;
  padding: 1rem;
  border-radius: 2.5rem 0;
  box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.25);

  &-image {
    width: 20%;
    height: auto;
    // background-color: $primary;
    border-radius: 2.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  &-text {
    text-align: left;
    width: 80%;
    display: flex;
    align-items: center;
    padding: 1rem;
    @include text;
  }
}
