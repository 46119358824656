@import "../../index.scss";

.entrance-header {
  // text-align: right;
  display: flex;
  justify-content: space-between;

  &-name {
    @include heading;
  }
}

.entrance-table {
  display: grid;
  grid-template-columns: auto; // Responsive single column layout by default
  max-height: 80vh;
  overflow-y: scroll;

  .entrance-table-header {
    &-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  .entrance-table-header,
  .entrance-row {
    display: flex;
    // display: grid;
    // grid-template-columns: 1fr 3fr 2fr 2fr 2fr;
    justify-content: space-between;
    font-size: 1.6rem;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    text-align: left;

    > div {
      padding: 5px;
    }

    .header-chekbox,
    .row-checkbox {
      width: 5%;
      text-align: left;
    }
    .header-id,
    .row-id {
      width: 15%;
    }
    .header-name,
    .row-name {
      width: 35%;
    }
    .header-whatsapp,
    .row-whatsapp {
      width: 15%;
    }
    .header-class,
    .row-class {
      width: 10%;
    }
    .header-timestamp,
    .row-timestamp {
      width: 15%;
    }
    .header-timestamp {
      cursor: pointer;
    }
  }

  .entrance-table-header {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  .entrance-table-footer {
    padding: 1rem 0;
    background-color: #f4f4f4;
    font-weight: bold;
  }

  .entrance-row {
    &-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &:hover {
      background-color: $pri15;
      //   background-color: $primary;
      //   color: white;

      cursor: pointer;
      //   border-radius: 1.5rem;
    }
  }
}

.entrance-row-green {
  background-color: rgb(169, 238, 169);
}

.emailcomp {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }

  p {
    margin-bottom: 15px;
    color: #666;
    font-size: 1.8rem;
  }

  .email-field {
    margin-bottom: 15px;
    text-align: left;

    label {
      display: block;
      font-size: 1.2rem;
      color: #555;
      margin-bottom: 5px;
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      font-size: 1.6rem;
      border: 1px solid #ddd;
      border-radius: 5px;
      outline: none;

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }
  }

  .email-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    button {
      flex: 1;
      padding: 10px 15px;
      font-size: 1rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:first-of-type {
        background: #007bff;
        color: #fff;

        &:hover {
          background: #0056b3;
        }
      }

      &:last-of-type {
        background: #f8f9fa;
        color: #333;
        border: 1px solid #ddd;

        &:hover {
          background: #e2e6ea;
        }
      }
    }
  }
}
