@import "../../index.scss";

.exam-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.exam-upload h2,
.exam-upload h3 {
  margin-bottom: 20px;
}

.section {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  width: 50%;
  @include shadowBlue; // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

// .exam-upload select,
.exam-upload .exam-button {
  margin: 10px 0;
  padding: 8px;
  width: 100%;
  background-color: $primary;
  border-radius: 1rem;
  color: white;
  cursor: pointer;
}

.exam-upload input[type="file"] {
  width: 100%;
  border: 1px solid $primary;
  padding: 1.2rem;
  border-radius: 1rem;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 1rem 0;
  margin-bottom: 3rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
