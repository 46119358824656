@import "../../index.scss";

.halflist {
  align-items: start;
  max-height: 70vh;
  overflow-y: scroll;
  border-radius: 2rem;

  // background-color: #4585e5c1;
  background-color: $secondary;

  &-header {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: $grey4;

    // &-count {
    //   color: white;
    //   font-weight: 700;
    //   font-size: 18px;
    // }

    &-search {
      width: 60%;

      & input {
        width: 100%;
        padding: 0.4rem 1rem;

        border-radius: 30px;
      }
    }

    // & button {
    //   flex: 3fr;
    //   // width: 30%;
    //   margin-left: 0.5rem;
    // }
  }

  &-content {
    flex: 1;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  &-footer {
    padding: 1rem;
    text-align: left;
    // background-color: $grey4;
    background-color: $primary;
    font-weight: 700;
    color: white;
  }

  .list-section,
  .details-section {
    flex: 1;

    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    position: relative;

    thead {
      // position: static;
      tr {
        background-color: $primary;
        color: white;

        th {
          padding: 10px 15px;
          border-bottom: 2px solid #ccc;
        }
      }
    }
  }

  .list-section {
    overflow-x: auto;
    height: 50vh;
    margin: 0 1rem;
    padding-bottom: 3rem;

    input[type="text"] {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;
      position: relative;
      border-collapse: separate;

      thead {
        // width: 80%;
        // position: static;

        // padding: 0 1rem;

        tr {
          background-color: white;
          margin: 0 1rem;
          color: $primary;
          border-radius: 2rem;
          border: 2px solid red;
          margin-bottom: 1rem;

          th {
            padding: 10px 15px;
            border-bottom: 2px solid #ccc;
            border-radius: 2rem;
          }
        }
      }
      &-rowHead {
        border-radius: 1rem;
      }

      tbody {
        padding: 1rem;
        tr {
          &:hover {
            background-color: $pri15;
            cursor: pointer;
          }

          td {
            padding: 10px 15px;
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }

  .details-section {
    h3 {
      margin-top: 0;
      color: #333;
    }

    p {
      margin: 5px 0;
      line-height: 1.6;

      strong {
        color: #555;
      }
    }

    button {
      padding: 10px 20px;
      margin-right: 10px;
      border: none;
      border-radius: 4px;
      background-color: #007bff;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }

      &:last-child {
        background-color: #dc3545;

        &:hover {
          background-color: #c82333;
        }
      }
    }
  }
}

@import "../../index.scss";

.halfAdd {
  & input[type="file"] {
    width: 100%;
    border: 1px solid $primary;
    padding: 1.2rem;
    border-radius: 1rem;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 1rem 0;
    margin-bottom: 3rem;
  }
}
