@import "../../index.scss";

.leaves {
  min-height: 60vh;
  padding: 5rem;

  @include respond(phone) {
    padding: 1.5rem;
  }

  &-header {
    display: flex;
    justify-content: space-between;

    &-title {
      @include heading;
      margin-bottom: 2rem;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }
}

.leave-application-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .rs-form-group {
    margin-bottom: 20px;

    .rs-form-control-label {
      display: block;
      margin-bottom: 5px;
      color: #333;
      font-weight: 500;
    }

    .rs-picker,
    .rs-input,
    .rs-picker-select,
    .rs-datepicker {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:focus {
        border-color: #4585e5;
        box-shadow: 0 0 0 2px rgba(69, 133, 229, 0.2);
      }
    }
  }

  .rs-btn-primary {
    display: block;
    width: 100%;
    margin-top: 20px;
    background-color: #4585e5;
    border-color: #4585e5;
    &:hover {
      background-color: #3672e4;
    }
  }
}
