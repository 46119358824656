.logs-page {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  &__header {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      font-size: 2.5rem;
      color: #007bff;
    }

    p {
      font-size: 1rem;
      color: #666;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .logs-page__search {
      display: flex;
      gap: 10px;

      input {
        width: 300px;
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      button {
        padding: 10px 20px;
        font-size: 1rem;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  &__loading {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
  }

  &__table-wrapper {
    overflow-x: auto;
    margin-top: 20px;

    .logs-page__table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 12px;
        text-align: left;
        border: 1px solid #ddd;
      }
      td {
        cursor: pointer;
      }

      th {
        background-color: #f9f9f9;
        font-weight: bold;
        color: #333;
      }

      tr:nth-child(even) {
        background-color: #f9f9f9;
      }

      &__status--captured {
        color: green;
        font-weight: bold;
      }

      &__status--failed {
        color: red;
        font-weight: bold;
      }

      &__status--pending {
        color: orange;
        font-weight: bold;
      }
    }
  }

  &__empty {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    margin-top: 20px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
      padding: 10px 15px;
      font-size: 1rem;
      margin: 0 10px;
      border: 1px solid #007bff;
      background-color: #007bff;
      color: white;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
        border-color: #ccc;
      }
    }

    span {
      font-size: 1rem;
      color: #666;
    }
  }
}

.logs-page__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: left;

    h2 {
      margin-bottom: 10px;
    }

    div {
      margin-bottom: 10px;
    }

    &-pre {
      background: #f9f9f9;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 4px;
      max-height: 300px;
      overflow-y: auto;
      font-size: 0.9rem;
    }
  }

  &-close {
    display: block;
    margin: 20px auto 0;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}
