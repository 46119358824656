@import "../../index.scss";

.installments-display {
  min-width: 600px;
  margin: 2rem 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;

  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  &-misc {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;

    &-inst {
      text-align: left;
      width: 60%;
      color: rgb(166, 49, 49);
    }
  }

  .installments-list {
    margin-bottom: 20px;
  }

  .installment-item {
    display: flex;

    flex-direction: column;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    cursor: move;

    &-options {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &:hover {
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    }

    .installment-select {
      width: 60%;
    }

    .installment-due-date {
      width: 30%;
    }

    button {
      width: 3%;
    }

    &-values {
      width: 70%;
      // margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }

  .total-fee {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }

  .installments-button {
    text-align: right;
    margin-top: 10px;
  }
}

.components {
  width: 98%;
  height: 100%;
  padding: 2rem;
  @include shadow;
  background-color: WHITE;
  margin-bottom: 1.5rem;

  &-title {
    @include textbig;
    font-weight: 700;
    color: $grey1;
  }

  &-total {
    font-size: 2rem;
    margin: 1rem 0;
  }

  &-row {
    margin-top: 1rem;
    &-values {
      font-size: 1.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.5rem;
      &-name {
        width: 60%;
      }
      &-amount {
        width: 30%;
      }
      &-account {
        width: 25%;
      }
    }
  }

  &-button {
    width: fit-content;
    margin: 1rem 0 0 auto;
  }
}
