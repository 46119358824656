@import "../../index.scss";

.resources {
  padding: 2rem;
  min-height: 60vh;

  &-title {
    @include heading;
    margin-bottom: 2rem;
  }
  .search-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    @include shadowBlue;
    border-radius: 15px;
    input {
      width: 1000%;
      padding: 10px;
      border: 0.5px solid $primary;
      border-radius: 5px;
    }
  }

  .documents-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    gap: 20px;
  }

  .document {
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @include respond(phone) {
      flex-direction: column;
    }

    &-name {
      font-size: 2rem;
      font-weight: 600;
    }
    .document-info {
      margin-bottom: 20px;

      h3 {
        margin-top: 0;
        color: #333;
      }
    }

    .download-button {
      display: inline-block;
      background-color: #007bff;
      color: #fff;
      padding: 10px 20px;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s;

      @include respond(phone) {
        margin-top: 1rem;
      }

      &:hover {
        background-color: darken(#007bff, 5%);
      }
    }
  }

  .error-message {
    color: #ff3860;
    text-align: center;
    width: 100%;
  }
}
