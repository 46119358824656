.UserDetails {
  min-height: 60vh;
  padding: 2rem 5rem;

  &-title {
    margin: 2rem 0;
    text-align: left;
  }

  &-inventory {
    margin: 4rem 0;
  }
}
