@import "../../index.scss";

.terms-container {
  width: 90%;
  margin: 40px auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;

  @include respond(phone) {
    width: 95%;
  }
}

.terms-container h1,
.terms-container h2 {
  color: #333;
}

.terms-container p,
.terms-container ul {
  color: #555;
  line-height: 1.6;
}

.terms-container ul {
  margin-left: 20px;
  list-style-type: disc;
}
