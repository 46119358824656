@import "../../index.scss";

.sd {
  padding: 2rem 5rem;
  @include Poppins;
  //   background-color: $primary;

  @include respond(phone) {
    padding: 1.5rem;
  }

  &-header {
    background-color: $pri15;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    &-meta {
      width: 35%;
      display: flex;
      // justify-content: space-between;

      &-img {
        width: 20rem;
        height: 20rem;
        position: relative;
        // min-width: 30%;

        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        // Hover effect to display buttons
        &:hover .img-buttons {
          display: flex;
        }

        // Buttons for download and change photo
        .img-buttons {
          position: absolute;
          display: none; // Hidden by default
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 10px;

          button {
            background-color: $primary; // Define primary color
            color: white;
            border: none;
            padding: 0.5rem 1rem;
            cursor: pointer;
            font-size: 1.2rem;
            border-radius: 5px;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: darken($primary, 10%);
            }
          }
        }
      }

      &-details {
        padding-top: 2rem;
        margin: 0 0 0 2rem;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-name {
          font-size: 2.4rem;
          font-weight: 600;
        }
        &-email {
          font-weight: 600;
        }

        &-cta {
          & button {
            margin-right: 1rem;
          }
          padding-bottom: 2rem;
        }
      }
    }

    &-msgs {
      background-color: white;
      margin: 1rem;
      width: 60%;
      border-radius: 2rem 0 2rem 0;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      &-list {
        height: 15vh;
        overflow-y: auto;
        padding: 1rem;

        & .msg-item {
          // width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: $pri15;
          margin: 1rem;
          border-radius: 0 3rem 3rem 0;

          &-meta {
            background-color: #0056b3;
            color: white;
            padding: 0.5rem 2rem 0.5rem 0.5rem;
            border-radius: 0 50px 50px 0;
            align-items: left;

            &-time {
              font-size: 1rem;
            }

            &-msg {
              padding: 0.5rem 1rem;
            }
          }
        }
      }

      &-sender {
        margin-top: auto;
      }
    }
  }

  &-warn {
    margin-bottom: 2rem;
    text-align: left;

    border: 2px solid $danger;
    background-color: $danger;
    color: white;
    border-radius: 0.5rem;
    width: fit-content;
    padding: 1rem;

    &-view {
      color: $success;
      text-decoration: underline;
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  &-contents {
    background-color: $pri15;
    padding: 3rem;

    @include respond(phone) {
      padding: 1rem;
    }

    &-title {
      @include textbig;
      font-weight: 700;
    }

    &-info {
      @include textbig;
      font-weight: 700;
      color: rgb(130, 37, 37);
    }

    &-section {
      text-align: left;
      margin: 2rem;

      &-title {
        font-size: 2.8rem;
        font-weight: 600;
        // color: white;

        @include respond(phone) {
          font-size: 2.2rem;
        }
      }

      &-buttons {
        display: flex;
        justify-content: end;
        margin-bottom: 1rem;
      }

      &-fields {
        padding: 2rem 4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 1.5rem;
        align-items: center;

        @include respond(phone) {
          padding: 1rem 0;
        }

        &-inst {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        &-field {
          //   margin-left: auto;
          width: 32%;
          // margin-bottom: 2rem;

          @include respond(tablet) {
            width: 48%;
          }
          @include respond(phone) {
            width: 100%;
          }

          & .input-error {
            border: px solid red;
          }

          &-other {
            width: 48%;
            margin-bottom: 2rem;
          }
        }
      }
      //   &-fields::after {
      //     content: "";
      //     flex: auto;
      //   }
    }

    &-seperator {
      border: none;
      border-bottom: 1px solid $grey3;
      width: 90%;
      margin: 0 auto;
    }
  }

  &-error {
    color: red;
    margin-bottom: 2rem;
    font-size: 2.4rem;
  }

  &-button {
    margin-top: 4rem;
  }

  &-result {
    margin-top: 4rem;
    text-align: left;
    @include textbig;
    font-weight: 700;
  }

  &-exam {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include shadowBlue;

    &-card {
      margin: 0.5rem;
      border: 1px solid $primary;
      width: 23%;
      @include shadowBlue;

      &-title {
        background-color: $grey4;
        padding: 1rem;
        font-weight: 700;
      }

      &-value {
        padding: 1rem;
      }
    }
  }

  &-hostel {
    margin: 2rem 0;
    max-height: 60vh;
    overflow-y: scroll;
  }

  &-leaves,
  &-invent {
    background-color: #d6eaff;
    padding: 2rem;
    margin: 2rem 0;
    border-radius: 2rem 0 2rem 0;
    @include shadow;
    &-title {
      text-align: left;
      @include textbig;
      font-weight: 700;
      margin-bottom: 2rem;
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
  }
}

.input-error {
  border: 2px solid red;
}

/* Hostel Allotment Section */
.hostel-assignment {
  background-color: $pri15;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h3 {
    color: #333;
    margin-bottom: 15px;
  }

  .available-beds {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    .bed-details {
      background-color: #fff;
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 14px;
        color: #555;
      }

      button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 8px 12px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

/* Assigned Bed Details Section */
.hostelDetails {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 50%;
  margin: 1rem auto;

  &-cards {
    display: flex;
    justify-content: center;

    &-card {
      background-color: white;
      margin: 1rem;
      padding: 1.5rem;
      border-radius: 1rem;
      @include shadow;

      &-name {
        color: $grey1;
        font-weight: 600;
      }
    }
  }

  h3,
  h4 {
    color: #333;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
  }
}

///////////////////////////////////////////
///
///
.message-sender {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #d1e6c0;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  .message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }

  .whatsapp-checkbox {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  .send-button {
    padding: 8px 15px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background: #0056b3;
    }
  }
}
