.announcements-display {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;

  .announcement-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    .announcement-header {
      background-color: #0056b3;
      color: #ffffff;
      padding: 0.5rem;

      .announcement-title {
        margin: 0;
        font-size: 1.5rem;
      }

      .announcement-date {
        font-size: 0.9rem;
        opacity: 0.9;
      }
    }

    .announcement-content {
      padding: 20px;
      border-top: 1px solid #e0e0e0;
      font-size: 1.8rem;
      font-weight: 400;
      color: #333;
    }
  }

  .no-announcements {
    text-align: center;
    padding: 20px;
    font-size: 1.2rem;
    color: #666;
  }
}
