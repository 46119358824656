@import "../../index.scss";

.header {
  // position: relative;
  height: 10vh;
  z-index: 100;
  background-color: $primary;

  position: fixed;
  width: 100%;
  box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.25);

  @include Poppins;

  &-logo {
    position: absolute;
    top: 2rem;
    left: 5rem;
    width: 10rem;
    height: auto;
    background-color: transparent;
    // border-radius: 1.5rem 1.5rem 5.5rem 5.5rem;

    @include respond(phone) {
      left: 2rem;
    }

    & img {
      width: 100%;
      height: auto;
      z-index: 125;
      border-radius: 0.5rem 0.5rem 5.5rem 5.5rem;
      cursor: pointer;
    }
  }

  &-bar {
    height: 100%;
    // border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &-full {
      @include respond(tablet) {
        display: none;
      }
    }

    &-phone {
      display: none;

      @include respond(tablet) {
        display: block;
        display: flex;
        justify-content: flex-end;

        & svg {
          margin: 1.5rem 0;
          margin-right: 5rem;

          @include respond(phone) {
            margin-right: 2rem;
          }
        }
      }
    }

    &-logo {
      // width: 20%;
      flex: 2;
    }

    &-links {
      flex: 6;
      padding: 1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      margin-right: 2rem;

      &-link {
        cursor: pointer;
        color: white;
        font-size: 1.6rem;
        font-weight: 300;
        margin: 0 3rem;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &-buttons {
    min-height: 100%;
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-right: 5rem;
    // clip-path: polygon(21% 0, 100% 0, 100% 100%, 0% 100%);

    & a {
      background-color: white;
      padding: 0.5rem 2.5rem;
      font-weight: 400;
      font-size: 1.6rem;
      text-decoration: none;
      color: $primary;
      border-radius: 1rem;
    }
  }
}
.activeLink {
  font-weight: 700;
  border-bottom: 2px solid $primary;
}
