@import "../../index.scss";

.enquiry-table {
  display: grid;
  grid-template-columns: auto; // Responsive single column layout by default
  max-height: 50vh;
  overflow-y: scroll;

  .enquiry-table-header,
  .enquiry-row {
    display: flex;
    // display: grid;
    // grid-template-columns: 1fr 3fr 2fr 2fr 2fr;
    font-size: 1.6rem;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    text-align: left;

    > div {
      padding: 5px;
    }

    .header-id,
    .row-id {
      width: 5%;
    }
    .header-name,
    .row-name {
      width: 30%;
    }
    .header-whatsapp,
    .row-whatsapp {
      width: 25%;
    }
    .header-class,
    .row-class {
      width: 20%;
    }
    .header-timestamp,
    .row-timestamp {
      width: 20%;
    }
    .header-timestamp {
      cursor: pointer;
    }
  }

  .enquiry-table-header {
    background-color: #f4f4f4;
    font-weight: bold;
  }

  .enquiry-row {
    &:hover {
      background-color: $pri15;
      //   background-color: $primary;
      //   color: white;

      cursor: pointer;
      //   border-radius: 1.5rem;
    }
  }
}
