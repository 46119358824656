@import "../../index.scss";

.sidebar {
  @include Poppins;
  width: 100%;
  height: 100vh;
  background-color: $pri15;
  color: white;
  padding: 20px;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;

  text-align: left;

  & a {
    color: $grey1;
    text-decoration: none;
    display: block;
    padding: 1.5rem;
    text-align: left;

    @include textbig;
  }
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.sidebar .active {
  background-color: $primary;

  border-radius: 1rem;
  cursor: pointer;
  color: white;
}

/* Additional styles as needed */
