@import "../../index.scss";

.submitted {
  @include Poppins;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 62vh;

  &-title {
    color: $green;
    font-size: 3.6rem;
    margin: 2rem 5rem;
    font-weight: 600;

    @include respond(phone) {
      margin: 2rem;
    }
  }

  &-text {
    font-size: 2.4rem;
    margin: 2rem 5rem;

    &-rollno {
      color: $primary;
    }
    @include respond(phone) {
      margin: 2rem;
    }
  }
  &-cta {
    margin: 2rem;
  }
}

.enquiry {
  display: flex;
  justify-content: space-between;
  background-color: $primary;
  @include Poppins;

  @include respond(tablet) {
    flex-direction: column;
  }

  @include respond(phone) {
    flex-direction: column;
  }

  &-left {
    width: 45%;
    background-color: white;
    padding: 5rem;

    box-shadow: 10px 10px 17px 0px rgba(0, 0, 0, 0.25);

    @include respond(tablet) {
      width: 100%;
    }
    @include respond(phone) {
      width: 100%;
      padding: 5rem 1.5rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 90%;

      &-ams {
        color: $primary;
        @include heading;
      }

      &-title {
        font-weight: 600;
        font-size: 6.4rem;

        @include respond(phone) {
          font-size: 4.2rem;
        }
      }

      &-sep {
        height: 1rem;
        width: 12rem;
        background-color: black;
        margin-bottom: 2rem;
      }
      &-text {
        font-size: 2.4rem;
        text-align: left;
        color: $grey2;

        @include respond(tablet) {
          margin-bottom: 4rem;
        }
        @include respond(phone) {
          font-size: 1.6rem;
          margin-bottom: 4rem;
        }
      }
    }

    &-contact {
      height: 10%;
      margin-top: auto;
      justify-self: flex-start;

      &-detail {
        text-decoration: none;
        color: $primary;
        display: block;
        text-align: left;
        @include text;
        font-weight: 600;
      }
    }
  }

  &-right {
    width: 50%;
    padding: 5rem;

    @include respond(tablet) {
      width: 100%;
      margin-top: 2rem;
      padding: 1.5rem;
    }

    @include respond(phone) {
      width: 100%;
      margin-top: 2rem;
    }

    &-title {
      color: white;
      margin-top: 1rem;
      font-size: 4.8rem;
      font-weight: 600;
      @include respond(phone) {
        font-size: 3rem;
      }
    }
    &-sep {
      margin-bottom: 2rem;
    }
    &-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin-bottom: 1rem;
      &-field {
        width: 45%;
        margin-bottom: 1rem;
        text-align: left;

        @include respond(phone) {
          width: 90%;
        }
      }
    }

    &-error {
      @include text;
      color: red;
      margin-bottom: 1rem;
    }

    &-button {
      margin-bottom: 2rem;
    }
  }
}

.invalid input {
  border: 5px solid red;
}

// .invalid {
//   border: 5px solid red;
// }
