.payment-result {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f7fa;
  padding: 15px;

  .result-card {
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    max-width: 450px;
    width: 100%;

    h1 {
      margin-bottom: 15px;
      font-size: 26px;
      color: #333;
      font-weight: 600;
    }

    p {
      color: #555;
      font-size: 16px;
      line-height: 1.5;
      margin: 10px 0;
      word-break: break-all;
    }

    button {
      margin-top: 25px;
      padding: 12px 30px;
      border: none;
      border-radius: 25px;
      background-color: #007bff;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }

    &.success {
      border-left: 5px solid #28a745;

      h1 {
        color: #28a745;
      }
    }

    &.error {
      border-left: 5px solid #dc3545;

      h1 {
        color: #dc3545;
      }
    }
  }
}
