@import "../../index.scss";

// Base modal styling
.rm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white; // Using variable for consistency
  padding: 2rem;
  border-radius: 10px;
  @include shadow; // Utilize your shadow mixin for consistency
  max-width: 300px;
  margin: 20px auto;
  text-align: center;
  transform: translateY(-40px); // Start position for animation
  opacity: 0; // Start fully transparent
  transition: all 0.3s ease-out; // Smooth transition for all changes

  // Animation to slide in and fade in
  animation: slideFadeIn 0.5s ease-out forwards;

  &-success,
  &-error {
    border-left-width: 5px;
    border-left-style: solid;
  }

  &-success {
    color: $success;
    border-left-color: $success; // Use your success color variable
    .rm-icon {
      color: $success; // Success icon color
    }
  }

  &-error {
    border-left-color: $danger; // Use your error color variable
    color: $danger;
    color .rm-icon {
      color: $danger; // Error icon color
    }
  }

  &-icon {
    margin-bottom: 1rem;
    // Icon grow and shrink animation
    animation: pulse 1.5s infinite;
  }

  &-msg {
    margin-bottom: 2rem;
    color: black;
    font-size: 1.4rem; // Slightly larger for readability
    @include text; // Apply text mixin for consistent typography
  }

  &-button {
    & > * {
      margin: 0 auto; // Center the button
    }
  }
}

// Keyframes for animations
@keyframes slideFadeIn {
  to {
    transform: translateY(0); // End at natural position
    opacity: 1; // Fully visible
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3); // Grows to 110% of its size
  }
}
