@import "../../index.scss";

.wip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;

  &-good {
    @include hero;
    color: $primary;
  }

  &-message {
    @include textbig;
    margin: 3rem 0;
  }
}
