@import "../../index.scss";

.asd {
  padding: 5rem;
  @include Poppins;
  //   background-color: $primary;

  @include respond(phone) {
    padding: 1.5rem;
  }

  &-success {
    margin-bottom: 2rem;
    text-align: left;

    &-text {
      border: 2px solid $success;
      background-color: $success;
      color: white;
      border-radius: 0.5rem;
      // width: fit-content;
      padding: 1rem;
    }

    &-view {
      color: $success;
      text-decoration: underline;
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  &-imgChange {
    display: flex;
    flex-direction: column;
    & input {
      display: block;
      margin: 1rem 0;
    }
  }

  &-cta {
    background-color: $primary;
    display: flex;
    justify-content: space-between;
    border-radius: 1rem 1rem;
    align-items: center;
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;

    &-back {
      color: $grey4;
      font-weight: 700;
      cursor: pointer;
      @include text;
    }

    &-note {
      @include textbig;
      font-weight: 700;
      color: white;
    }

    &-buttons {
      & img {
        cursor: pointer;
        height: 4rem;
        width: auto;
        margin-left: 2rem;
      }
    }
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-back {
      position: absolute;
      top: 1rem;
      left: 1rem;
      font-weight: 700;
      color: white;
      cursor: pointer;
    }

    &-logo {
      & img {
        width: 9rem;
        height: auto;
        border-radius: 50%;

        @include respond(tablet) {
          width: 5rem;
        }
      }
    }

    &-title {
      font-weight: 600;
      font-size: 3.8rem;
      width: 50%;
      margin: 0 auto;
      color: $grey4;

      @include respond(tablet) {
        font-size: 3rem;
      }
      @include respond(phone) {
        font-size: 2rem;
      }
    }

    &-ams {
      font-size: 4.8rem;
      color: white;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }

  .asd-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    background-color: $pri15;
    padding: 1rem;
    position: relative;

    &-img {
      width: 20rem;
      height: 20rem;
      position: relative;
      // min-width: 30%;

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      // Hover effect to display buttons
      &:hover .img-buttons {
        display: flex;
      }

      // Buttons for download and change photo
      .img-buttons {
        position: absolute;
        display: none; // Hidden by default
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px;

        button {
          background-color: $primary; // Define primary color
          color: white;
          border: none;
          padding: 0.5rem 1rem;
          cursor: pointer;
          font-size: 1.2rem;
          border-radius: 5px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: darken($primary, 10%);
          }
        }
      }
    }

    &-details {
      // width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-name {
        font-size: 2.6rem;
        text-transform: uppercase;
        font-weight: 700;
      }
      &-email {
        font-weight: 600;
      }
    }

    &-cta {
      // width: 15%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      gap: 2rem 0;
    }
  }

  &-contents {
    background-color: $pri15;
    padding: 3rem;

    @include respond(phone) {
      padding: 1rem;
    }

    &-title {
      @include textbig;
      font-weight: 700;
    }

    &-info {
      @include textbig;
      font-weight: 700;
      color: rgb(130, 37, 37);
    }

    &-section {
      text-align: left;
      margin: 2rem;

      &-title {
        font-size: 2.8rem;
        font-weight: 600;
        // color: white;

        @include respond(phone) {
          font-size: 2.2rem;
        }
      }

      &-fields {
        padding: 2rem 4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include respond(phone) {
          padding: 1rem 0;
        }

        &-field {
          width: 32%;
          // margin-bottom: 0.5rem;

          @include respond(tablet) {
            width: 48%;
          }
          @include respond(phone) {
            width: 100%;
          }

          & .input-error {
            border: px solid red;
          }

          &-other {
            width: 48%;
            margin-bottom: 2rem;
          }
        }
      }
      //   &-fields::after {
      //     content: "";
      //     flex: auto;
      //   }
    }

    &-seperator {
      border: none;
      border-bottom: 1px solid $grey3;
      width: 90%;
      margin: 0 auto;
    }
  }

  &-error {
    color: red;
    margin-bottom: 2rem;
    font-size: 2.4rem;
  }

  &-button {
    margin-top: 2rem;
    // text-align: right;
    display: flex;
    justify-content: space-between;

    &-left {
      gap: 1rem;
      & > * {
        margin-right: 2rem;
      }
    }
  }

  &-result {
    margin-top: 4rem;
    text-align: left;
    @include textbig;
    font-weight: 700;
  }

  &-exam {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include shadowBlue;

    &-card {
      margin: 0.5rem;
      border: 1px solid $primary;
      width: 23%;
      @include shadowBlue;

      &-title {
        background-color: $grey4;
        padding: 1rem;
        font-weight: 700;
      }

      &-value {
        padding: 1rem;
      }
    }
  }

  &-additional {
    margin: 5rem 0;
    text-align: left;
    font-size: 1.6rem;

    &-header {
      @include textbig;

      font-weight: 700;
    }
  }
  &-notadditional {
    margin: 5rem 0;
    text-align: left;
    font-size: 1.6rem;

    &-header {
      @include textbig;

      font-weight: 700;
    }

    &-amtText {
      font-size: 2rem;
      margin: 1.5rem 0;
      font-weight: 600;
    }

    & label {
      margin-top: 1.5rem;
    }

    & button {
      margin: 2rem 0;
    }
  }

  &-comments {
    margin-top: 3rem;
    width: 100%;
    text-align: left;
    background-color: $grey4;
    padding: 2rem;
    // border-radius: 2rem;

    &-none {
      @include textbig;
    }

    &-heading {
      @include heading;
      margin-bottom: 2rem;
    }

    &-card {
      background-color: white;
      @include shadowBlue; //   background-color: $pri15;
      //   border-radius: 2rem;
      padding: 1.5rem;
      margin: 2rem 0;

      &-data {
        &-user {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      &-comment {
        // @include text;
        color: black;
        font-size: 1.8rem;
      }
    }

    &-add {
      margin: 2rem 0;
      width: 100%;
      display: flex;
      justify-content: space-between;

      & textarea {
        @include shadowBlue;
        width: 72%;
        height: 6rem;
        border: 1px solid $primary;
        border-radius: 1.5rem;
        padding: 1.5rem;
      }

      & button {
        width: 25%;
      }
    }
  }
}

.input-error {
  border: 2px solid red;
}

.scholarship-approval {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 1.5em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    color: #666;
    font-size: 1em;
    line-height: 1.4;
  }

  .otp-input {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-verified {
      color: $success;
      font-weight: 700;
    }

    label {
      display: block;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &-box {
      width: 30%;
    }

    input[type="text"] {
      display: inline-block;
      width: 70%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-bottom: 10px;
    }

    button {
      margin-left: 1rem;
    }
  }
}
