@import "../../index.scss";

.vendors {
  padding: 2rem;

  &-header {
    &-name {
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 2rem;
      text-align: left;
    }
  }
  &-contents {
    display: flex;
    justify-content: space-between;

    &-list {
      width: 36%;
    }
    &-details {
      width: 60%;

      &-noselection {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
