@import "../../index.scss";

.verification-container {
  max-width: 60%;
  margin: 0 auto;
  padding: 20px;
  //   border: 1px solid #ddd;
  //   border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @include shadow;

  @include respond(phone) {
    max-width: 90%;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #2c3e50;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #34495e;
  }

  .input-container {
    margin-bottom: 20px;

    label {
      display: block;
      font-size: 14px;
      margin-bottom: 5px;
      color: #7f8c8d;
    }

    input {
      // width: calc(100% - 22px);
      margin-top: 30px;
      width: 100%;
      padding: 10px;
      font-size: 14px;
      border: 1px solid #bdc3c7;
      border-radius: 4px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }

    button {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      color: #fff;
      background-color: #3498db;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #2980b9;
      }
    }
  }

  .error-message {
    color: #e74c3c;
    font-size: 14px;
  }
}
