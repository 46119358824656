@import "../../index.scss";

.assignments {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
}

.assignment-card {
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;

  .card-header {
    background: #f7f7f7;
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    h3 {
      margin: 0;
      font-size: 18px;
      color: #333;
    }

    span {
      display: block;
      margin-top: 5px;
      font-size: 14px;
      color: #666;
    }
  }

  .card-body {
    padding: 15px;

    p {
      margin: 10px 0;
      font-size: 14px;
      color: #444;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
