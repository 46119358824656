@import "../../index.scss";

//halfpage styles in layout.

.assignhistory {
  &-title {
    @include textbig;
    font-weight: 600;
    margin: 2rem 0;
  }
}
.assignments-table {
  .table-row-green {
    padding: 1rem 0;
    border-radius: 1rem 0 1rem 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    align-items: center;
    background-color: $success;
    color: white;
    font-weight: 600;
  }
  .table-header,
  .table-row {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    align-items: center;
  }
  .table-header {
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  .table-row {
    padding: 10px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}
p {
  color: #666;
}
