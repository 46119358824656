@import "../../index.scss";

.roled {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: "Helvetica Neue", Arial, sans-serif;

  h2 {
    color: #2a2a2a;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
  }

  .menu-item {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 20px;

    h3 {
      color: #2a2a2a;
      margin-bottom: 15px;
      font-size: 18px;
      border-bottom: 2px solid #eaeaea;
      padding-bottom: 5px;
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;

      span {
        flex: 1;
        margin-right: 15px;
        color: #666;
      }

      .checkbox-label {
        display: flex;
        align-items: center;
        margin-right: 15px;
        cursor: pointer;
        color: #444;

        input[type="checkbox"] {
          accent-color: #007bff; /* Change this color for checkbox accent */
          margin-right: 5px;
        }
      }
    }
  }
}

// .roled {
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 20px;
//   font-family: "Arial", sans-serif;

//   h3 {
//     color: #333;
//     margin-bottom: 10px;
//   }

//   .menu-item {
//     border-bottom: 1px solid #eee;
//     padding-bottom: 20px;
//     margin-bottom: 20px;

//     &:last-child {
//       border-bottom: none;
//     }
//   }

//   .dropdown-item {
//     display: flex;
//     align-items: center;
//     margin-bottom: 5px;
//     font-size: 14px;

//     span {
//       flex: 1;
//       margin-right: 10px;
//     }

//     .checkbox-label {
//       display: flex;
//       align-items: center;
//       margin-right: 15px;
//       cursor: pointer;

//       input[type="checkbox"] {
//         margin-right: 5px;
//       }
//     }
//   }
// }
