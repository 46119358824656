@import "../../index.scss";

.idCardPhotoList {
  min-height: 70vh;
  padding: 2rem;

  .idCardPhotoList-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .idCardPhotoList-header-title {
      font-size: 30px;
      font-weight: bold;
    }

    .idCardPhotoList-header-button {
      button {
        padding: 10px 15px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  .filter {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 20vw;
    }

    & .custdrop {
      width: 200px;
    }
  }

  .list {
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border: 1px solid #eee;
      border-radius: 5px;
      margin-bottom: 10px;
      @include shadowBlue;

      input[type="checkbox"] {
        margin-right: 10px;
      }

      a {
        padding: 5px 10px;
        background-color: #28a745;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        margin-left: 1rem;

        &:hover {
          background-color: #218838;
        }
      }

      & .IDphotopreview {
        background-color: #007bff;
      }
    }
  }
}
