@import "../../index.scss";

.timetableDetails {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 60vh;

  &-meta {
    text-align: center;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      @include heading;
      font-weight: 600;
      text-decoration: underline;
    }
    h2 {
      color: #333;
      margin: 0;
      padding: 0;
    }
  }

  .timetable-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;

    @include respond(phone) {
      width: 100%;
      overflow-x: scroll;
    }

    .day-column {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      padding: 15px;
      display: flex;
      flex-direction: column;

      @include respond(phone) {
        min-width: 20rem;
      }

      .day-header {
        color: #2979ff;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
        text-align: center;
      }

      .classCard {
        padding: 10px;
        background-color: #e3f2fd;
        border-radius: 6px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #333;

        &:last-child {
          margin-bottom: 0;
        }

        &-time {
          font-size: 1.6rem;
          font-weight: 600;
        }
        &-subject {
          font-size: 1.6rem;

          color: $grey1;
        }
        &-teacher {
          font-size: 1.6rem;
        }
        &-buttons {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
          & > * {
            margin: 0 1rem;
          }
        }
      }
    }
  }
}
