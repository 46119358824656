@import "../../index.scss";

.instDiscount {
  width: 100%;
  height: 100%;
  padding: 2rem;
  @include shadow;
  background-color: WHITE;
  border-radius: 1rem;

  &-meta {
    margin-bottom: 3rem;
    background: $pri15;
    padding: 1.5rem 2rem;

    border-radius: 0 2rem 0 2rem;
    box-shadow: 0 4px 6px $black25;

    &-title {
      margin-bottom: 1rem;
      @include textbig;
      color: $primary;
      font-weight: 600;
    }

    &-content {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: start;
      > div {
        width: 30%;

        &:nth-last-child(2) {
          color: $success;
          font-weight: bold;
        }
      }

      > div {
        background: $white;
        padding: 10px;
        border-radius: 5px;
        border-left: 5px solid $primary;
        color: $grey1;
      }
    }

    // Responsive adjustments
    @include respond(phone) {
      padding: 15px;

      > div {
        margin-bottom: 10px;
      }

      > div:first-child {
        font-size: $mheading;
      }

      > div:not(:first-child) {
        padding: 8px;
      }
    }
  }

  &-title {
    @include textbig;
    font-weight: 700;
    color: $grey1;
  }

  &-total {
    font-size: 2rem;
    margin: 1rem 0;
  }

  &-row {
    margin-top: 1rem;
    &-values {
      font-size: 1.8rem;
      display: flex;
      justify-content: space-between;
      border-radius: 0.5rem;
      &-name {
        width: 30%;
      }
      &-amount {
        width: 10%;
      }
      &-account {
        width: 10%;
      }
      &-late {
        width: 7%;
      }
      &-discount {
        width: 20%;
        display: flex;
        justify-content: space-between;

        &-name {
          width: 60%;
        }
        &-amount {
          width: 30%;
        }
        &-button {
          width: 4%;
        }
      }
      &-due {
        width: 13%;
      }
      &-remove {
        width: 5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-card,
    &-header {
      font-size: 1.8rem;
      font-weight: 600;
      border-bottom: 1px solid $grey1;
      border-radius: 0.5rem;
      display: flex;
      // justify-content: space-between;

      &-name {
        width: 32%;
      }
      &-amount {
        width: 11%;
      }
      &-discount {
        width: 26%;
      }
      &-account {
        width: 11%;
      }
      &-due {
        // width: 10%;
      }
    }
  }

  &-conflict {
    text-align: center;
    font-weight: 500;
    color: $danger;
  }

  &-button {
    width: fit-content;
    margin: 1rem 0 0 auto;
  }
}
