@import "../../index.scss";

.elist {
  padding: 20px;
  width: 100%;
  @include Poppins;

  &-heading {
    @include heading;
  }

  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-filters {
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-label {
      text-align: left;
      margin-left: 1rem;
      color: $primary;
    }

    &-search {
      width: 30%;

      & input {
        width: 100%;
        padding: 1rem 1.5rem;
        border: 2px solid $primary;
        border-radius: 2.5rem;
      }
    }

    &-datepicker {
      width: 25%;
    }

    &-class {
      width: 20%;
    }
  }

  &-table {
    width: 100%;

    &-header {
      display: flex;
      font-size: 1.6rem;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid #ccc;
      background-color: #f4f4f4;
      font-weight: bold;
      text-align: left;

      > div {
        padding: 5px;
      }

      .header-id,
      .row-id {
        width: 5%;
      }
      .header-name,
      .row-name {
        width: 30%;
      }
      .header-whatsapp,
      .row-whatsapp {
        width: 25%;
      }
      .header-class,
      .row-class {
        width: 20%;
      }
      .header-timestamp,
      .row-timestamp {
        width: 20%;
      }
      .header-timestamp {
        cursor: pointer;
      }
    }

    &-footer {
      background-color: #f4f4f4;
      font-weight: 600;
      text-align: left;
      @include text;
      padding: 1rem;
    }
  }
}
