@import "../../index.scss";

.custom-time-picker {
  & label {
    display: inline-block;
  }
  .time-input {
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid $primary;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    display: block;
    width: 100%;
    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
}
