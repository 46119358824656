@import "../../index.scss";

.asd {
  padding: 5rem;
  @include Poppins;
  //   background-color: $primary;

  @include respond(phone) {
    padding: 1.5rem;
  }

  &-cta {
    background-color: $primary;
    display: flex;
    justify-content: space-between;
    border-radius: 1rem 1rem;
    align-items: center;
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;

    &-back {
      color: $grey4;
      font-weight: 700;
    }

    &-note {
      @include textbig;
      font-weight: 700;
      color: white;
    }

    &-buttons {
      & img {
        cursor: pointer;
        height: 4rem;
        width: auto;
        margin-left: 2rem;
      }
    }
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &-back {
      position: absolute;
      top: 1rem;
      left: 1rem;
      font-weight: 700;
      color: white;
      cursor: pointer;
    }

    &-logo {
      & img {
        width: 9rem;
        height: auto;
        border-radius: 50%;

        @include respond(tablet) {
          width: 5rem;
        }
      }
    }

    &-title {
      font-weight: 600;
      font-size: 3.8rem;
      width: 50%;
      margin: 0 auto;
      color: $grey4;

      @include respond(tablet) {
        font-size: 3rem;
      }
      @include respond(phone) {
        font-size: 2rem;
      }
    }

    &-ams {
      font-size: 4.8rem;
      color: white;

      @include respond(phone) {
        font-size: 3rem;
      }
    }
  }

  &-contents {
    background-color: $pri15;
    padding: 3rem;

    @include respond(phone) {
      padding: 1rem;
    }

    &-title {
      @include textbig;
      font-weight: 700;
    }

    &-info {
      @include textbig;
      font-weight: 700;
      color: rgb(130, 37, 37);
    }

    &-section {
      text-align: left;
      margin: 2rem;

      &-title {
        font-size: 2.8rem;
        font-weight: 600;
        // color: white;

        @include respond(phone) {
          font-size: 2.2rem;
        }
      }

      &-fields {
        padding: 2rem 4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include respond(phone) {
          padding: 1rem 0;
        }

        &-field {
          //   margin-left: auto;
          width: 32%;
          // margin-bottom: 2rem;

          @include respond(tablet) {
            width: 48%;
          }
          @include respond(phone) {
            width: 100%;
          }

          & .input-error {
            border: px solid red;
          }

          &-other {
            width: 48%;
            margin-bottom: 2rem;
          }
        }
      }
      //   &-fields::after {
      //     content: "";
      //     flex: auto;
      //   }
    }

    &-seperator {
      border: none;
      border-bottom: 1px solid $grey3;
      width: 90%;
      margin: 0 auto;
    }
  }

  &-error {
    color: red;
    margin-bottom: 2rem;
    font-size: 2.4rem;
  }

  &-button {
    margin-top: 2rem;
  }

  &-comments {
    margin-top: 3rem;
    width: 100%;
    text-align: left;
    background-color: $grey4;
    padding: 2rem;
    // border-radius: 2rem;

    &-heading {
      @include heading;
      margin-bottom: 2rem;
    }

    &-card {
      background-color: white;
      @include shadowBlue; //   background-color: $pri15;
      //   border-radius: 2rem;
      padding: 1.5rem;
      margin: 2rem 0;

      &-data {
        &-user {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }

      &-comment {
        // @include text;
        color: black;
        font-size: 1.8rem;
      }
    }

    &-add {
      margin: 2rem 0;
      width: 100%;
      display: flex;
      justify-content: space-between;

      & textarea {
        @include shadowBlue;
        width: 72%;
        height: 6rem;
        border: 1px solid $primary;
        border-radius: 1.5rem;
        padding: 1.5rem;
      }

      & button {
        width: 25%;
      }
    }
  }
}

.input-error {
  border: 2px solid red;
}
