@import "../../index.scss";

.resultsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  min-height: 60vh;

  &-title {
    @include heading;
    font-weight: 700;
    margin-bottom: 10px;
    color: #007bff;

    @include respond(phone) {
      @include textbig;

      font-weight: 700;
    }
  }

  p {
    margin-bottom: 20px;
    font-style: italic;
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    @include respond(phone) {
      flex-direction: column;
      width: 70%;
    }

    input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;

      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }

    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: #007bff;
      color: white;
      font-size: 16px;
      cursor: pointer;
      &:disabled {
        background-color: #ccc;
      }

      &:hover:not(:disabled) {
        background-color: #0056b3;
      }
    }
  }

  .resultsDisplay {
    width: 100%;
    max-width: 600px;

    .resultsList {
      .resultItem {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #f9f9f9;

        div {
          margin: 5px 0;
        }
      }
    }
  }
}
